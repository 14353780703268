<template>
  <div class="app-mana">
    <div class="app-title">应用管理</div>
    <div class="app-content">
      <div class="flex-bet-top app-tab">
        <div>
            <el-button class="add-app" size="small" @click="addApp(1)">新建自建应用</el-button>
            <!-- <el-button class="add-app" size="small" @click="addApp(2)">新增三方应用</el-button> -->
        </div>
        <div style="display:flex;">
          <!-- <el-select v-model="type" placeholder="请选择应用" size="mini" @change="changeTpye" style="margin-right:10px;">
            <el-option  label="全部" value=""></el-option>
            <el-option  label="自建应用" :value="1"></el-option>
            <el-option  label="三方应用" :value="2"></el-option>
          </el-select> -->
          <el-form ref="form" size="small" :model="form" @submit.native.prevent>
            <el-form-item >
              <el-input v-model="form.search" @input="clearInput"  @keyup.enter.native="goSearch" placeholder="请输入搜索关键字">
              </el-input>
                <i @click="goSearch" class="el-icon-search search-btn"></i>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-table :data="tableData" style="width:100%" v-loading="loading">
        <el-table-column
            label="应用名称"
            width="230">
          <template slot-scope="scope">
            <div>{{scope.row.app_name}}</div>
            <div class="fz12">应用ID：{{scope.row.app_id}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="状态">
          <template slot-scope="scope">
            <div>{{scope.row.status===1 ? '启用 ': scope.row.status===2 ? "禁用":'删除'}}</div>
          </template>
        </el-table-column>
        <el-table-column
            prop="create_time"
            sortable
            label="设置时间">
        </el-table-column>
        <el-table-column
            prop="remarks"
            label="备注"
            width="130" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.remarks ? scope.row.remarks :'--'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                @click="handleCheck(scope.$index, scope.row)">查看</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="edit_application"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="scope.row.status!==3 && del_application"
                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="scope.row.status===3"
                @click="recover(scope.$index, scope.row)">恢复</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex-end" v-show="tableData.length">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {appLists,delApp} from '@/api/workbench'
export default {
  name: "AppMana",
  data(){
    return {
      type:'', //1=自建；2=三方
      form:{
        search:'',//搜索
      },
      loading:true,
      pageInfo: {
        page: 1, //默认一页
        limit: 10, //默认每页十条
      },
      total:0,
      tableData:[],//表格
    }
  },
  created() {
    this.getAppList(1,10)
  },
  computed:{
    edit_application(){
      return this.$store.state.listData.edit_application
    },
    del_application(){
      return this.$store.state.listData.del_application
    },
  },
  methods:{
    //自建还是三方
    changeTpye(e){
      this.type = e
      this.getAppList(1,10)
    },
    getAppList(page,page_size,app_name){//获取应用列表
      appLists({page,page_size,app_name,type:this.type}).then(res=>{
        if(res.code===200){
          this.tableData=res.data.data
          this.total=res.data.total
        }else{
          this.$message({
            message: '获取数据失败，请稍后再试',
            type: 'warning'
          })
        }
        this.loading=false
      }).catch(()=>{
        this.loading=false
      })
    },
    handleCheck(index,row){//查看
      this.$router.push({
        query:{id:row.id,type:1,Propstype:row.type},
        path:'/home/appmana/detail'
      })
    },
    handleEdit(index,row){//编辑
      console.log(row,'row')
      this.$router.push({
        query:{id:row.id,Propstype:row.type},
        path:'/home/appmana/detail'
      })
    },
    handleDelete(index,row){//删除
      const that=this;
      that.$confirm('此操作将永久删除该应用, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApp({id:row.id}).then(res=>{
          if(res.code===200){
            that.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getAppList(1,10);
          }else{
            that.$message.error('删除失败，请稍后重试');
          }
        })

      }).catch(() => {

      });
    },
    recover(index,row){//恢复
      console.log(index,row);
    },
    addApp(type){//添加应用
      this.$router.push({path:'/home/appmana/detail',query:{Propstype:type}})
    },
    handleCurrentChange(page){//分页
      this.getAppList(page,10,this.form.search?this.form.search:null)
    },
    goSearch(){//搜索
      if(this.form.search){
        this.loading=true;
        this.getAppList(1,10,this.form.search)
      }else{
        this.$message({
          message: '请输入搜索关键字',
          type: 'warning'
        })
      }
    },
    clearInput(){//清空搜索返回全部
      if(!this.form.search){
        this.loading=true;
        this.getAppList(1,10)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.app-mana{
  .app-title{
    height: 48px;
    line-height: 48px;
    background: #F8F9FA;
    text-align: center;
    border-bottom: 1px solid #DBDBDB;
  }
  .app-content{
      padding: 44px 100px;
    .app-tab{
      .add-app{
        background: #0078F0;
        color:#fff;
      }
      /deep/.el-form{
        width: 220px;
        position: relative;
        .search-btn{
          position: absolute;
          right:12px;
          top:11px;
          cursor: pointer;
        }
      }

    }
    /deep/.el-table{
      margin-bottom: 24px;
      /deep/thead{
        /deep/th{
          padding:2px 0;
          font-weight: 400;
          color:#606266;
        }
        /deep/tr,th,td{
          background: #DFE9F5!important;
        }
      }

      .tab-btn{
        color:#004580;
      }

    }

  }
}
</style>